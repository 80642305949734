body {
  font-size: 16px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

&::-webkit-scrollbar {
  width: 7px;
  height: 7px;

  background-color: #ededed;
}
*::-webkit-scrollbar-track {
  background-color: rgb(163 164 163);
  border-radius: 8px;
}
&::-webkit-scrollbar-thumb {
  background: #4b4b4b;
  border-radius: 7px;
}

/* 기본 화살표 제거 */
input[type='number'] {
  -webkit-appearance: none; /* Safari 및 Chrome에서 화살표 버튼 제거 */
  -moz-appearance: textfield; /* Firefox에서 화살표 버튼 제거 */
  appearance: none; /* 모든 브라우저에서 기본 스타일 제거 */
}

/* Firefox에서 input의 기본 스타일을 추가로 제거하기 */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
