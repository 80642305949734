.responsive-text-size {
  font-size: calc(2rem + 1vw); /* 기본 크기 + 화면 너비 비율 */
}

.primary-title-text {
  font-size: calc(2.5rem + 1vw);
  font-weight: 700;
  white-space: nowrap;
}

.sidebar-listitem-text {
  font-size: calc(0.4rem + 1vw);
  font-weight: 600;
  white-space: nowrap;
}

.keypad > text {
  font-size: calc(1.5rem + 1vw);
  color: white;
  font-weight: 700;
}

.tablet-header text {
  font-size: calc(0.2rem + 1vw);
  font-weight: 600;
  white-space: nowrap;
}

.even-shadow {
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.25);
}

.white-hover {
  &:hover {
    background-color: #f6f6f6;
  }
}

.font-white {
  color: white;
}

.row-justify-start {
  justify-content: flex-start;
}
.row-justify-center {
  justify-content: center;
}
.row-justify-end {
  justify-content: flex-end;
}
.flex-gap-0dot5rem {
  gap: calc(0.5rem + 1vw);
}
.flex-gap-1rem {
  gap: calc(1rem + 1vw);
}
.flex-gap-1dot5rem {
  gap: calc(1.5rem + 1vw);
}
.flex-gap-2rem {
  gap: calc(2rem + 1vw);
}
.flex-gap-2dot5rem {
  gap: calc(2.5rem + 1vw);
}
.less-spacing {
  letter-spacing: 0;
}

/* @media (max-width: 600px) {
    .responsive-text {
      font-size: 1rem; 
    }
  } */
