.react-calendar {
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  overflow: hidden;
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  padding: 10px 0px;
  font-size: 20px;
  color: #727272;
  margin-bottom: 1px;
}

.react-calendar__month-view__weekdays__weekday:first-child {
  color: #f19b06;
}

.react-calendar abbr[title] {
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: default;
}

.container {
  background-color: white;
  width: 500px;
  /* height: 700px; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.control-panel {
  display: flex;
  width: 500px;
  height: 38px;
  background-color: white;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: 20px;
  font-weight: 700;
  color: #79797e;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.control-button.hide {
  display: none;
  pointer-events: none;
}

.react-calendar__month-view__days {
  /* padding: 5px; */
  background-color: white;
  color: #79797e;
  font-size: 16px;
  border: none;
}

.react-calendar__month-view__days__day {
  padding: 3px 0px;
  background-color: white;
  color: #79797e;
  font-size: 16px;
  border: none;
}

.react-calendar__tile--active {
  background-color: #deeeff !important;
  /* color: #fff; */
}

.react-calendar__tile:hover {
  background-color: #d4d4d4;
}

.highlight:hover {
  background: #deeeff;
}
